<template>
  <div class="nav-bar van-hairline--top">
    <ul class="nav-list">
      <router-link tag="li" class="nav-list-item active" to="home" replace>   <!--原来是to="home"-->
        <i class="iconfont icon-home"></i>
        <span>首页</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="category" replace>
        <i class="iconfont icon-category"></i>
        <span>课程</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="store" replace>
        <i class="iconfont icon-store"></i>
        <span>书肆</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="user" replace>
        <i class="iconfont icon-user"></i>
        <span>我的</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getLocal } from '@/utils/util.js'

  export default {
    setup() {
      const route = useRoute()
      const store = useStore()
      onMounted(() => {
        const token = getLocal('token')
        const path = route.path
        if (token && !['/store', '/category'].includes(path)) {
          // store.dispatch('updateCart')
        }
      })
      const count = computed(() => {
        return store.state.cartCount
      })
      return {
        count
      }
    }
  }
</script>

<style lang="scss" scoped>
  // $primary: #F8493E; // 主题色
  // $primary: #F76968;
  $primary: #F94840;

  .nav-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    z-index: 1000;
    background: #fff;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    .nav-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      .nav-list-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-align: center;
        color: #666;
        &.router-link-active {
          color: $primary;
        }
        i {
          text-align: center;
          font-size: 48px;
        }
        span {
          font-size: 16px;
        }
        .van-icon-shopping-cart-o {
          margin: 0 auto;
          margin-bottom: 2px;
        }
      }
    }
  }
</style>
