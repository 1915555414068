export function getQueryString(name) {
  var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if(r != null) {
      return  unescape(r[2]);
  } else {
      return null
  }
}

export const getLocal = (name) => {
  return localStorage.getItem(name)
}

export const setLocal = (name,value) => {
  localStorage.setItem(name,  JSON.stringify(value))
}

export const removeLocal = (name) => {
  localStorage.removeItem(name)
}
