import axios from "@/utils/axios"
import api from "@/api"

// 发送短信验证码
export function sendVerifyCode( params ) {
  console.log("发送验证码：", params);
  return api.get(`/code/getVerificationCode`, {
    params:{
      mobile:params
    }
  }
  )
}
// 获取用户信息
export function userInfo( userId ) {
  console.log("获取用户信息");
  return api.get('/user/userInfo', {
    params:{
      userId:userId
    }
  });
}

// 获取用户信息
export function isVip( userId ) {
  console.log("是否是全站会员");
  return api.get('/user/isVip', {
    params:{
      userId:userId
    }
  });
}

// 登录
export function login( params ) {
  console.log("用户登录：", params);
  return api.post('/user/login', params);
}
//获取用户openid
export function getOpenId(code) {
  console.log("获取用户openid：", code);
  return api.get(`/getOAuth`, {
    params:{
      code:code
    }
  });
}


// 获取地址列表
export function getAddressList( userId ) {
  console.log("user token:");
  return api.get('/user/addressList', {
    params:{
      userId:userId
    }
  });
}
// 获取地址
export function getAddress( id ) {
  console.log("获取地址：",id);
  return api.get('/user/address', {
    params:{
      id:id
    }
  });
}

// 获取地址列表
export function saveAddress( params ) {
  console.log("新增/编辑地址:", params);
  return api.post('/user/editAddress',params);
}

// 删除地址
export function delAddress( params ) {
  console.log("删除地址:", params);
  return api.post('/user/delAddress',params);
}
// 设置密码
export function settingPassword( params ) {
  console.log("setting password:",);
  return api.post('/user/password', params);
}
// 获取订单列表
export function getOrderList( userId,type ) {
  console.log("get order list:");
  return api.get('/order/list', {
   params:{
     userId:userId,
     type:type
   }
  });

}

// 获取配送信息
export function getDelivery( orderNo ) {
  console.log("获取配送信息");
  return api.get('/order/delivery', {
    params:{
      orderNo:orderNo,
    }
  });

}
// 获取默认收货地址
export function getDefaultAddress( params ) {
  console.log("get default delivery:", params);
  return axios.get('/mock/user/getDefaultAddress', params);
}
// 获取用户优惠券
export function getCouponList( userId,type) {
  return api.get("/user/getCouponList", {
    params:{
      userId:userId,
      type:type,
    }
  });
}

// 是否有未领取的优惠券
export function noGetCoupons( userId) {
  return api.get("/user/haveCoupons", {
    params:{
      userId:userId,
    }
  });

}

// 未领取优惠券列表
export function noGetCoupList( userId) {
  return api.get("/user/drawCoupons", {
    params:{
      userId:userId,
    }
  });
}

//领取优惠券

// 购买
export function receiveCoupons( param ) {
  return api.post("/user/getCoupons",param);
}
