<template>
  <div class="category">
    <van-search
      v-model="searchValue"
      shape="round"
      background="#fff"
      placeholder="请输入课程关键词"
      maxlength="12"
      @update:model-value="onSearch"
      class="van-hairline--bottom"
    />
    <van-tree-select
      v-model:active-id="activeId"
      v-model:main-active-index="activeIndex"
      :items="labelTree"
      @click-nav="onNavClick"
    >
      <template #content>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
          @offset="10"
          class="course-list"
        >
          <div class="course" v-for="(item, index) in list" :key="index" @click="goto(item.id)">
            <div class="cover">
              <van-image width="100%" height="100%" fit="cover" :src="item.cover"/>
            </div>
            <div class="intro">
              <div class="marquee">
                <div class="marquee-wrap">
                  <div class="marquee-content ">
                    {{item.name}}
                  </div>
                </div>
              </div>

              <div class="sell">
                <div class="price">
                  <del style="color: #888888;">￥{{item.origPrice}}</del>
                  <span>￥{{item.marketPrice}}</span>
                </div>
              </div>
              <span>{{item.buy}}人买过</span>
            </div>
            <div class="label">{{item.labelName}}</div>
          </div>
        </van-list>
      </template>
    </van-tree-select>

    <nav-bar/>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import navBar from "@/components/NavBar";
  import { getCourseList,getLabels} from '@/service/course'
  import { isVip } from '@/service/user'

  export default {
    name: "Category",
    components: {
      navBar,
    },
    setup() {
      const activeId = ref(3);
      const activeIndex = ref(0);
      const router = useRouter();
      const storage= localStorage.getItem("token-hyxt");
      const token=JSON.parse(storage);
      let searchValue = ref('');
      const vip=ref(0);
      const labelTree= ref([]);
      const state = reactive({
        list: [],
        page: 1,
        loading: false,
        finished: false,
        finishedText:""
      });
      const onChangeTab = () => {
        state.list = [];
        state.page = 1;
        state.finished = false;
        state.loading = true;
        onLoad();
      }
      const getTree = async ()=>{
        let res =await getLabels();
        labelTree.value=res.data.data;
       let index= router.currentRoute.value.query.activeIndex;
       if(index){
         activeIndex.value=index;
         activeId.value=labelTree.value[index].id;
       }
      }
      const loadData = async () => {
        if(token){
          isVip(token.userId).then(res=>{
            vip.value=res.data.status;
          })
        }
        let res = await getCourseList(activeId.value,searchValue.value);
        state.list = res.data.data;
        if(state.list.length < 1) {
          console.log("<1")
          state.finishedText = "暂无数据";
          state.finished = true; //上拉结束
        }else if(state.list.length >= 7) {   //修改位res.data.list.length < 1 适配正式环境
          state.finishedText = "没有更多了";
          state.finished = true; //上拉结束
          console.log(">=7")
        } else {
          console.log("》1")
          state.finished = true; //上拉结束
          state.loading = false;
          state.finishedText = "没有更多了";
        }
      };
      const onLoad = () => {
        loadData();
        state.page++;
      }
      const goto = (id) => {
        router.push({path: '/courseDetail', query: {id: id}});
      };
      onMounted(() => {
        getTree();
      })

      function onSearch(){
        loadData();
      }

      function onNavClick(index){
        activeId.value=labelTree.value[index].id;
        loadData();
      }



      return {
        ...toRefs(state),
        searchValue,
        onSearch,
        onChangeTab,
        activeIndex,
        labelTree,
        onLoad,
        goto,
        activeId,
        vip,
        onNavClick
      };
    },
    watch: {
      'activeIndex': 'onChangeTab'
    }
  };
</script>

<style lang="scss" scoped>
  .category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .van-tree-select {
      flex: 1;
      .course-list {
        margin: 12px 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-bottom: 90px;
        .course {
          width: 48%;
          border: 1px solid #cdcdcd;
          border-radius: 12px;
          margin-bottom: 12px;
          background-color: #fff;
          .cover {
            height: 230px;
          }
          .intro {
            height: 90px;
            padding: 12px 12px 0px 12px;
            .name {
              font-size: 26px;
              font-weight: 500;
              line-height: 36px;
            }
            .marquee {
              overflow: hidden;
              .marquee-wrap {
                width: 50px;
                animation: marquee-wrap 4s infinite linear;
              }
              .marquee-content {
                float: left;
                white-space: nowrap;
                min-width: 50px;
                animation: marquee-content 4s infinite linear;
              }
            }
            @keyframes marquee-wrap {
              0%,
              30% {
                transform: translateX(0);
              }
              70%,
              100% {
                transform: translateX(100%);
              }
            }

            @keyframes marquee-content {
              0%,
              30% {
                transform: translateX(0);
              }
              70%,
              100% {
                transform: translateX(-100%);
              }
            }
            .sell {
              display: flex;
              flex-direction: column;
              align-items: baseline;
              line-height: 42px;
              .price {
                display: flex;
                justify-content: space-between;
                color: rgb(242, 85, 0);
                font-size: 23px;
                width: 100%;
                .vip{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
              .amount {
                font-size: 18px;
                color: #707070;
              }
            }
          }
          .label{
            position: relative;
            left: 140px;
            top: -695px;
            width: 80px;
            height: 19px;
            background: #F8483F;
            border-radius: 9.6px;
            font-size: 15px;
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
